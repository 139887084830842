// UserHashtagCard.js
'use strict';
import { connect } from 'react-redux';

import { PUBLIC_BADGE_URL_PATH } from '../RemoteConfigKeys.js';

import UserHashtagCard from '../component/UserHashtagCard.jsx';
import getUserData from '../selector/getUserData.js';
import { HASHTAG_FEED_USER, USER_HASHTAG } from '../RemoteConfigKeys.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getLiveStreamData from '../selector/getLiveStreamData.js';
import getUserBadges from '../selector/getUserBadges.js';
import getUsername from '../selector/getUsername.js';

const mapStateToProps = (state, { userId }) => {
  return {
    publicBadgeUrlPath: getRemoteConfigData(state, PUBLIC_BADGE_URL_PATH),
    hashtagFeedUser: getRemoteConfigData(state, HASHTAG_FEED_USER),
    shouldShowUserHashtags: getRemoteConfigData(state, USER_HASHTAG) === '1',
    isStreaming: getLiveStreamData(state, [userId], 'isLiveStreaming'),
    displayName: getUsername(state, userId, 'displayName'),
    username: getUserData(state, userId, 'username'),
    hashtags: getUserData(state, userId, 'hashtags'),
    countries: getUserBadges(state, userId, 'countries'),
  };
};

export default connect(mapStateToProps, null)(UserHashtagCard);
